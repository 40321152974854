import behave from "./helpers/behave";

behave('.menu-cursor', element => {
    const check = element.querySelector('.menu-cursor-check')

    check.checked = localStorage.getItem("cursor") !== "1"
    document.body.classList.toggle('paw', check.checked)

    check.addEventListener('change', () => {
        document.body.classList.toggle('paw', check.checked)
        localStorage.setItem("cursor", check.checked ? "0" : "1")
    })

    element.style.removeProperty('display')
})
