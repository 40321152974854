import Swup from 'swup'
import behave, {init} from "./helpers/behave";
import parent from "./helpers/parent";

behave(".page", () => {
    const menu = document.querySelector('.menu')
    const trigger = document.querySelector('.menu-trigger')

    const swup = new Swup({
        containers: ['.page', '.logo', '.menu-right']
    });

    swup.on('clickLink', e => {
        const link = parent(e.target, el => el.tagName === "A");

        trigger.classList.remove('menu-trigger--active')
        menu.classList.remove('menu--active')

        const href = link.getAttribute('href');

        if (!href || !href.startsWith("/")) {
            return;
        }

        menu.querySelectorAll('span.pill').forEach(
            element => {
                const a = document.createElement('a')
                a.setAttribute('href', element.dataset.href)
                a.classList.add('pill')
                a.innerHTML = element.innerHTML
                menu.replaceChild(a, element);
            }
        )

        menu.querySelectorAll('a.pill').forEach(
            element => {
                if (element.getAttribute('href') !== href) {
                    return
                }

                const span = document.createElement('span')
                span.dataset.href = element.getAttribute('href')
                span.classList.add('pill')
                span.innerHTML = element.innerHTML
                menu.replaceChild(span, element);
            }
        )
    })

    swup.on('contentReplaced', () => {
        window.scrollTo(0, 0)
        init(document.querySelector('.page'))
        init(document.querySelector('.menu'))
    });
})
