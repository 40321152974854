import behave from "./helpers/behave";

behave("._more", btn => {
    const perPage = parseInt(btn.dataset.page);
    const projects = Array.from(btn.parentElement.querySelectorAll(".projects-row"))
        .filter(el => el.style.display)

    if (!perPage) {
        return;
    }

    btn.addEventListener('click', e => {
        e.preventDefault()
        e.stopPropagation()

        projects.splice(0, perPage).forEach(el => el.style.display = '')

        if (projects.length === 0) {
            btn.style.display = 'none'
        }
    })
})
