import behave from "./helpers/behave";

behave(".menu-trigger", trigger => {
    const menu = trigger.parentNode.querySelector(".menu")

    trigger.addEventListener('click', () => {
        trigger.classList.toggle("menu-trigger--active")
        menu.classList.toggle("menu--active")
    })
})
