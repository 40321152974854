var initers = []

export function init(container = document) {
    initers.forEach(initer => initer(container))
}

if (document.readyState === "loading") {
    window.addEventListener('DOMContentLoaded', () => init())
} else {
    setTimeout(() => init(), 10)
}

export default function behave(selector, initFn) {
    var initKey = 'behave-' + Math.random().toString().replace('.', '')

    var initer = container => {
        container.querySelectorAll(selector).forEach(
            element => {
                if (!element.dataset[initKey]) {
                    initFn(element)
                }

                element.dataset[initKey] = "true"
            },
        )
    }

    initers.push(initer)
    return initer
}
